<template>
  <div class="exam-list-wrapper">
    <div class="my-4">
      <div class="type-list flex flex-wrap items-center mb-3">
        <span class="mb-2">试卷类型：</span>
        <el-button type="primary" :plain="typeAction(item.val)" size="small" v-for="item of typeList" :key="item.val" class="mb-2" @click="selectType(item)">{{ item.name }}</el-button>
      </div>
      <div class="type-list flex flex-wrap items-center">
        <span class="mb-2">等级分类：</span>
        <el-button type="primary" :plain="levelAction(item.val)" size="small" v-for="item of levelList" :key="item.val" class="mb-2" @click="selectLevel(item)">{{ item.name }}</el-button>
      </div>
    </div>
    <div v-if="examList.length > 0">
      <div class="exam-list flex flex-wrap">
        <div 
          class="md:w-1/3 w-full cursor-pointer"
          :class="[index % 3 === 0 ? 'md:pb-3 md:px-0 pb-3':'md:pl-3 md:pr-0 md:pb-3 pb-3']"
          v-for="(item, index) of examList"
          :key="item.id"
          @click="goExamDetail(item)"
        >
          <el-card shadow="hover" class="text-left">
            <p class="text-xl" style="min-height: 56px;">{{ item.name }}</p>
            <div class="text-gray-500 my-2">
              <p>类型：{{ typeName(item.type) }}</p>
              <p>题目数量：{{ item.question_num }}</p>
              <p>试卷总分：{{ item.score }}</p>
              <p>建议时长：{{ item.suggest_time }}</p>
              <p>发布时间：{{ item.create_time }}</p>
            </div>
            <div class="flex flex-wrap" v-if="item.tags.length > 0">
              <el-tag v-for="(tag, index) of item.tags" :key="index" :type="tag === '免费'?'success':tag === 'VIP'?'danger':'primary'" class="mr-2 mb-2">{{ tag }}</el-tag>
            </div>
          </el-card>
        </div>
      </div>
      <el-pagination
        class="my-4 md:hidden"
        background
        small
        layout="prev, pager, next"
        :page-size="query.num"
        :total="examTotal"
        :current-page.sync="currentPage"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
      <el-pagination
        class="my-4 md:block hidden"
        background
        layout="prev, pager, next"
        :page-size="query.num"
        :total="examTotal"
        :current-page.sync="currentPage"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <el-empty v-else description="暂时没有试卷"></el-empty>
  </div>
</template>

<script>
import { list } from '@/api/exam'
import checkPermission from '@/utils/permission'

export default {
  name: 'ExamList',
  data() {
    return {
      typeList: [
        { name: '全部', val: -1 },
        { name: 'Scratch', val: 0 },
        { name: 'C++', val: 1 },
        { name: 'Python', val: 2 },
        { name: 'NOIP', val: 3 },
        { name: '机器人', val: 4 },
      ],
      levelList: [
        { name: '全部', val: -1 },
        { name: '一级', val: 1 },
        { name: '二级', val: 2 },
        { name: '三级', val: 3 },
        { name: '四级', val: 4 }
      ],
      query: {
        type: -1,
        level: -1,
        page: 1,
        num: 9
      },
      examList: [],
      examTotal: 0,
      currentPage: 1
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      list(this.query).then(res => {
        this.examList = res.data.list
        this.examTotal = res.data.count
      })
    },
    typeAction(val) {
      if (this.query.type === val) {
        return !true
      }
      return !false
    },
    levelAction(val) {
      if (this.query.level === val) {
        return !true
      }
      return !false
    },
    typeName(val) {
      return this.typeList.find(item => {
        return item.val === val
      })?.name
    },
    selectType(item) {
      this.query.type = item.val
      this.query.page = 1
      this.currentPage = 1
      this.init()
    },
    selectLevel(item) {
      this.query.level = item.val
      this.query.page = 1
      this.currentPage = 1
      this.init()
    },
    handleCurrentChange(val) {
      this.query.page = val
      this.init()
    },
    goExamDetail(row) {
      if (row.is_vip === 1) {
        if (!checkPermission(['vip'])) {
          this.$message({
            type: 'warning',
            message: '抱歉，您没有权限。开通 VIP 即可使用所有试卷！',
            duration: 2000
          })
          return false
        }
      }
      const hrefData = this.$router.resolve({ 
        path: '/exam/detail',
        query: { id: row.id }
      })
      window.open(hrefData.href, 'exam-page')
      // this.$router.push({ path: '/exam/detail', query: { id: row.id }})
    }
  }
}
</script>